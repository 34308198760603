import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { mainBackground, secondaryBackground } from "../const";

const ButtonContainer = styled.div`
  ${tw`
    flex
    justify-center
  `}
  width: 100%;
  padding-top: ${(props) => props.paddingTop};
`;

const PrimaryButton = styled.button`
  ${tw`
    px-10
    py-2
    rounded-xl 
    text-center
    font-semibold
    mt-4
  `}
  width: 100%;
  position: relative;
  overflow: hidden;
  font-size: ${(props) => props.fontSize || "18px"};
  background: ${(props) => props.color || "#00dada"};
  color: ${mainBackground};
  border: 1px solid ${(props) => props.color || "#00dada"};
`;

const Ripple = styled.span`
  @keyframes rip {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(4);
      opacity: 0;
    }
  }

  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: rip 500ms linear;
  width: 150px;
  height: 150px;
  background: ${(props) => props.color || "#00dada"};
`;

const BlockedButton = styled.button`
  ${tw`
    px-10
    py-2
    rounded-xl 
    text-center
    font-semibold
    mt-4
  `}
  width: 100%;
  font-size: ${(props) => props.fontSize || "18px"};
  background: ${secondaryBackground};
  color: #a0a0a0;
  border: 1px solid #f0f0f0;
`;

export default function FullButton(props) {
  const { onClickFunction, color, mainBackground } = props;
  const [ripple, setRipple] = React.useState(false);

  function handleClick(event) {
    setRipple(true);
    setTimeout(() => {
      setRipple(false);
      onClickFunction && onClickFunction(event);
    }, 300);
  }

  return (
    <ButtonContainer paddingTop={props.paddingTop} onClick={handleClick}>
      {props.active && (
        <PrimaryButton
          type="submit"
          fontSize={props.fontSize}
          color={color}
          mainBackground={mainBackground}
        >
          {props.text}
          {ripple && <Ripple color={color} />}
        </PrimaryButton>
      )}

      {!props.active && (
        <BlockedButton
          type="submit"
          disabled
          fontSize={props.fontSize}
          mainBackground={mainBackground}
        >
          {props.text}
        </BlockedButton>
      )}
    </ButtonContainer>
  );
}
