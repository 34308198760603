import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import ItemField from "./itemField"
import SeeButton from "./SeeMoreButton"
import { Tab } from "@headlessui/react"
import './TabItemList.css'
import { secondaryBackground } from "../const"
import ItemWarehouse from "./ItemWarehouse"
import PrintItemList from "./PrintItemList"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const startingItems = 3;

const ItemContainer = styled.div`
  ${tw`
      flex
      flex-col
      justify-self-center
      items-center
  `}
  padding-bottom: 30px;
  width: 100%;
  background-color: ${secondaryBackground};
`

const ButtonsContainer = styled.div`
  ${tw`
      flex
      flex-row
      justify-center
      w-full
      items-center
      mt-4
  `}
`

const StyledLink = styled.div`
  ${tw`
      flex
      w-full
      text-center
  `}
  margin-top: -5px;
  font-size: 18px;
  color: white;
  font-weight: 500;

  .linkText{
    width: 100%;
  }
`

const CountDiv = styled.div`
  ${tw`
      w-full
      flex
      justify-center
      pb-2
  `}

  color: #00dada;
`

export default function TabItemList(props){
  const [nItems, setItems] = React.useState(startingItems);
  const [prevN, setPrev] = React.useState(0);
  const {noSellItems, soldItems, infoFunc} = props;

  function showMore(){
    setPrev(nItems);
    setItems(nItems + 40);
  }

  function showInfo(item){
    infoFunc(item);
  }

  return(
    <ItemContainer>
      <Tab.Group>
        <Tab.List className=" customList flex space-x-1 rounded-xl p-1 mb-3">
          <Tab className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-1 text-sm font-normal customTab',
                  'ring-comBg focus:ring focus:outline-none',
                  selected
                    ? 'selectedTab shadow'
                    : 'hover:text-white'
                )
              }>Acquisti</Tab>
          <Tab className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-normal leading-5 customTab',
                  'ring-comBg focus:ring focus:outline-none',
                  selected
                    ? 'selectedTab shadow'
                    : 'hover:text-white'
                )
              }>Vendite</Tab>
        </Tab.List>
        <Tab.Panels className="tabPanel">
          <Tab.Panel className="individualPanel">
            {noSellItems.length > 0 &&
              <PrintItemList purchases={noSellItems} sales={soldItems}/>
            }
            {
              noSellItems.slice(0, nItems).map((item, index) => (
                <ItemWarehouse 
                  item={item}
                  sales = {false}
                  index={index}
                  nItems={prevN}
                  infoFunc={showInfo}
                  key={index}
                />

              ))
            }
          </Tab.Panel>
          <Tab.Panel className="individualPanel">
          {soldItems.length > 0 &&
              <PrintItemList purchases={noSellItems} sales={soldItems}/>
            }
            {
              soldItems.slice(0, nItems).map((item, index) => (
                <ItemWarehouse 
                  item={item}
                  sales = {true}
                  index={index}
                  nItems={prevN}
                  infoFunc={showInfo}
                  key={index}
                />

              ))
            }
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <StyledLink onClick={showMore}>
        <p className="linkText">
        Mostra altri
        </p>
      </StyledLink>
    </ItemContainer>
  );
}