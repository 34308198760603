import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from "styled-components";
import { formatDate, getForfettaryTaxes, mapMonth, months, secondaryText, years } from '../const';
import tw from 'twin.macro';
import './PDFGenerator.css'
import download_icon from "../icons/download_icon.png"
import ReactDOMServer from 'react-dom/server';
import finix_banner from "../logos/finix_full_whitebg.png"

const StyledDiv = styled.div`
  color: black;
`

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  page-break-inside: avoid;
`

const StyledTable = styled.table`
  width: 650px;
  border-collapse: collapse;
  border-spacing: 0;

  .priceC{
    width: 100px;
  }
  
  .nameC{
    max-width: 260px;
    line-height: 1.1;
    word-wrap: break-word;
    padding-inline: 10px;
  }
  
  .typeC{
    width: 130px;
  }
  
  .dateC{
    width: 160px;
  }

  td + .valueTable{
    text-align: left;
    padding-left: 30px;
  }
`

const HeaderRow = styled.tr`
  background-color: black;
  color: white;
`

const TableHeader = styled.th`
  text-align: center;
`

const StyledData = styled.td`
  text-align: center;
`

const TableRow = styled.tr`
  ${props => props.colored ? 'background: lightgrey;' : 'background: white;'}
  color: black;

  .voiceTable{
    text-align: left;
    padding-left: 20px;
  }
`

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 35%;

  img{
    width: 50%;
    height: auto;
  }
`

const DownloadContainer = styled.div`
  ${tw`
      flex
      w-full
      justify-center
    `}

  margin-bottom: 15px;
`
const DownloadRow = styled.div`
  ${tw`
      flex
      items-center
  `}

  border: 2px solid #00dada;
  color: #00dada;
  padding: 5px 15px;
  border-radius: 10px;
`
const DownloadText = styled.div`
    font-size: 16px;
    margin-right: 2px;
`
const DownloadIcon = styled.img`
    width: 20px;
    height: 20px;
`

export default function PrintItemList(props){
  const {purchases, sales} = props;

  const [shoes, setShoes] = React.useState([]);
  const [generics, setGenerics] = React.useState([]);
  const [watches, setWatches] = React.useState([]);

  const [shoesSales, setShoesSales] = React.useState([]);
  const [genericsSales, setGenericsSales] = React.useState([]);
  const [watchesSales, setWatchesSales] = React.useState([]);


  React.useEffect(() => {
    if(purchases){
      var w = [];
      var s = [];
      var g = [];
      for(const item of purchases){
        if(item.type == 'shoe') s.push(item)
        else if(item.type == 'watch') w.push(item)
        else g.push(item)
      }

      setShoes(s);
      setGenerics(g);
      setWatches(w);
    }
    if(sales){
      var w = [];
      var s = [];
      var g = [];
      for(const item of sales){
        if(item.type == 'shoe') s.push(item)
        else if(item.type == 'watch') w.push(item)
        else g.push(item)
      }

      setShoesSales(s);
      setGenericsSales(g);
      setWatchesSales(w);
    }
  }, [purchases, sales])

  const itemsPerPage = 22;

  const itemList = () => {


    return (
      <>
        <page>
        <div className="bodyClass">
          <div className="container">
            <div className="paddingContainer">
              <BannerContainer>
                <img src={finix_banner} />
              </BannerContainer>
              <StyledDiv className="reportTitle">
                Lista oggetti
              </StyledDiv>
              <div className="secondTitle">
                Di seguito la lista degli oggetti che stavi visualizzando nell'app. Sono divisi tra acquisti e vendite, e successivamente per categoria.
            </div>
            </div>
          </div>
        </div>
        </page>
        {(shoes.length > 0 || watches.length > 0 || generics.length > 0) && <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                <div className="sectionTitle">
                  Lista acquisti
                </div>
                {shoes.length > 0 && <div className="secondaryTitle">
                  Lista scarpe
                </div>}
                {shoes.length > 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Taglia</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {shoes.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.shoe.sizeEU}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
                {watches.length > 0 && shoes.length === 0 && <div className="secondaryTitle">
                  Lista orologi
                </div>}
                {watches.length > 0 && shoes.length === 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Modello</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {watches.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.watch.modelNumber}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
                {generics.length > 0 && watches.length === 0 && shoes.length === 0 &&<div className="secondaryTitle">
                  Lista generici
                </div>}
                {generics.length > 0 && watches.length === 0 && shoes.length === 0 &&<TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {generics.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
              </div>
              </div>
              </div>
            </div>
          </div>
        </page>}
        {shoes.length > 0 && extraShoes(shoes, true)}
        {watches.length > 0 && shoes.length === 0 && extraWatches(watches, true)}
        {generics.length > 0 && watches.length === 0 && shoes.length === 0 && extraGenerics(generics, true)}
        {shoes.length > 0 && watches.length > 0 && <page>
        <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                {watches.length > 0 && <div className="secondaryTitle">
                  Lista orologi
                </div>}
                {watches.length > 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Modello</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {watches.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.watch.modelNumber}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
                {generics.length > 0 && watches.length === 0 && <div className="secondaryTitle">
                  Lista generici
                </div>}
                {generics.length > 0 && watches.length === 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {generics.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
              </div>
              </div>
              </div>
            </div>
          </div>
        </page>}
        {watches.length > 0 && extraWatches(watches, true)}
        {generics.length > 0 && watches.length === 0 && extraGenerics(generics, true)}
        {(shoes.length > 0 || watches.length > 0) && generics.length > 0 && <page>
        <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                {generics.length > 0 && <div className="secondaryTitle">
                  Lista generici
                </div>}
                {generics.length > 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {generics.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
              </div>
              </div>
              </div>
            </div>
          </div>
        </page>}
        {generics.length > 0 && watches.length === 0 && shoes.length === 0 && extraGenerics(generics, true)}
        {(shoesSales.length > 0 || watchesSales.length > 0 || genericsSales.length > 0) && <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                <div className="sectionTitle">
                  Lista vendite
                </div>
                {shoesSales.length > 0 && <div className="secondaryTitle">
                  Lista scarpe
                </div>}
                {shoesSales.length > 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Taglia</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {shoesSales.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.shoe.sizeEU}</StyledData>
                        <StyledData className="dateC">{formatDate(item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
                {watchesSales.length > 0 && shoesSales.length === 0 && <div className="secondaryTitle">
                  Lista orologi
                </div>}
                {watchesSales.length > 0 && shoesSales.length === 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Modello</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {watchesSales.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.watch.modelNumber}</StyledData>
                        <StyledData className="dateC">{formatDate(item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
                {genericsSales.length > 0 && watchesSales.length === 0 && shoesSales.length === 0 &&<div className="secondaryTitle">
                  Lista generici
                </div>}
                {genericsSales.length > 0 && watchesSales.length === 0 && shoesSales.length === 0 &&<TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {genericsSales.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
              </div>
              </div>
              </div>
            </div>
          </div>
        </page>}
        {shoesSales.length > 0 && extraShoes(shoesSales, false)}
        {watchesSales.length > 0 && shoesSales.length === 0 && extraWatches(watchesSales, false)}
        {genericsSales.length > 0 && watchesSales.length === 0 && shoesSales.length === 0 && extraGenerics(genericsSales, false)}
        {shoesSales.length > 0 && watchesSales.length > 0 && <page>
        <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                {watchesSales.length > 0 && <div className="secondaryTitle">
                  Lista orologi
                </div>}
                {watchesSales.length > 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Modello</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {watchesSales.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.watch.modelNumber}</StyledData>
                        <StyledData className="dateC">{formatDate(item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
                {genericsSales.length > 0 && watchesSales.length === 0 && <div className="secondaryTitle">
                  Lista generici
                </div>}
                {genericsSales.length > 0 && watchesSales.length === 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {genericsSales.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
              </div>
              </div>
              </div>
            </div>
          </div>
        </page>}
        {watchesSales.length > 0 && extraWatches(watchesSales, false)}
        {genericsSales.length > 0 && watchesSales.length === 0 && extraGenerics(genericsSales, false)}
        {(shoesSales.length > 0 || watchesSales.length > 0) && genericsSales.length > 0 && <page>
        <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                {genericsSales.length > 0 && <div className="secondaryTitle">
                  Lista generici
                </div>}
                {genericsSales.length > 0 && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {genericsSales.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="dateC">{formatDate(item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
              </div>
              </div>
              </div>
            </div>
          </div>
        </page>}
        {generics.length > 0 && watches.length === 0 && shoesSales.length === 0 && extraGenerics(genericsSales, false)}
      </>
    )
  }

  const extraShoes = (shoes, purchases) => {
    var additionalPages = [];
    for( let i = itemsPerPage; i < shoes.length; i += itemsPerPage){
      const currentItems = shoes.slice(i, i + itemsPerPage);
      if (purchases){
        additionalPages.push(renderExtraShoesTable(currentItems, purchases))
      }
      else{
        additionalPages.push(renderExtraShoesTable(currentItems, purchases))
      }
    }

    return(
      <>
        {additionalPages}
      </>
    )
  }

  const renderExtraShoesTable = (items, purchases) => (
    <page>
      <div className="bodyClass">
        <div className="container">
          <div className="paddingContainer">
            <div className="reportBody">
              <div className="sectionSubtitle">Lista scarpe</div>
              {items && (
                <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                    <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Taglia</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {items.map((item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.shoe.sizeEU}</StyledData>
                        <StyledData className="dateC">{formatDate(purchases ? item.buyDate : item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{purchases ? item.buyPrice : item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </page>
  )

  const extraWatches = (watches, purchases) => {
    var additionalPages = [];
    for( let i = itemsPerPage; i < watches.length; i += itemsPerPage){
      const currentItems = watches.slice(i, i + itemsPerPage);
      if (purchases){
        additionalPages.push(renderExtraWatchesTable(currentItems, purchases))
      }
      else{
        additionalPages.push(renderExtraWatchesTable(currentItems, purchases))
      }
    }

    return(
      <>
        {additionalPages}
      </>
    )
  }

  const renderExtraWatchesTable = (items, purchases) => (
    <page>
      <div className="bodyClass">
        <div className="container">
          <div className="paddingContainer">
            <div className="reportBody">
              <div className="sectionSubtitle">Lista scarpe</div>
              {items && (
                <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                    <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Modello</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {items.map((item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.watch.modelNumber}</StyledData>
                        <StyledData className="dateC">{formatDate(purchases ? item.buyDate : item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{purchases ? item.buyPrice : item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </page>
  )

  const extraGenerics = (items, purchases) => {
    var additionalPages = [];
    for( let i = itemsPerPage; i < items.length; i += itemsPerPage){
      const currentItems = items.slice(i, i + itemsPerPage);
      if (purchases){
        additionalPages.push(renderExtraGenericsTable(currentItems, purchases))
      }
      else{
        additionalPages.push(renderExtraGenericsTable(currentItems, purchases))
      }
    }

    return(
      <>
        {additionalPages}
      </>
    )
  }

  const renderExtraGenericsTable = (items, purchases) => (
    <page>
      <div className="bodyClass">
        <div className="container">
          <div className="paddingContainer">
            <div className="reportBody">
              <div className="sectionSubtitle">Lista scarpe</div>
              {items && (
                <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                    <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="dateC">Data</TableHeader>
                      <TableHeader className="priceC">Prezzo</TableHeader>
                    </HeaderRow>
                    {items.map((item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="dateC">{formatDate(purchases ? item.buyDate : item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{purchases ? item.buyPrice : item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </page>
  )



  const printHandler = () => {
    const printElement = ReactDOMServer.renderToString(itemList());

    html2pdf().from(printElement).save();
  }

  return(
    <DownloadContainer onClick={() => printHandler()}>
      <DownloadRow>
        <DownloadText>Scarica lista</DownloadText>
        <DownloadIcon src={download_icon}/>
      </DownloadRow>
    </DownloadContainer>
  )
}