import tw from "twin.macro";
import styled from "styled-components"
import errorIcon from "../icons/error_icon.png"

const ErrorContainer = styled.div`
  ${tw`
      flex
      flex-row
      items-baseline
      w-full
  `}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.left && 'justify-content: start;'}
  
  margin-bottom: 15px;
  margin-top: -25px;
  color: rgb(255, 0, 0);
  font-size: 12px;

  ul{
    font-weight: 400;
    list-style-type: disc;
    padding-left: 15px;
  }

  li{
    margin-bottom: 3px;
  }
  
`
const IconContainer = styled.div`
  margin-right: 5px;
  width: 15px;
  height: 15px;
`

export default function ErrorMessage(props){
  const {text, left, width, ul, ulOptions, icon} = props;

  return(
    <ErrorContainer left={left} width={width}>
      <IconContainer>
        <img src={errorIcon} />
      </IconContainer>
      <div>
      <p>{text}</p>
      {ul && <ul>
          {ulOptions.map((op, index) => (
            <li key={index}>{op}</li>
          ))}
        </ul>}
        </div>
    </ErrorContainer>
  )
}