import styled from "styled-components"
import tw from "twin.macro";

const Field = styled.div`
    ${tw`
        relative  
        mb-7
        flex
        items-center
    `}
    width: ${props => props.width};

    input{
      position: relative;
      width: 100%;
      padding: 20px 10px 10px;
      background: transparent;
      outline: none;
      border: none;
      box-shadow: none;
      color: white;
      opacity: 1;
      font-size: 1em;
      letter-spacing: 0.05em;
      transition: 0.5s;
    }

    span{
      position: absolute;
      left: 0;
      padding: 20px 10px 10px;
      pointer-events: none;
      color: white;
      font-size: 1.1em;
      letter-spacing: 0.05em;
      transition: 0.5s;
    }

    input:valid ~ span,
    input:focus ~ span{
      color: white;
      font-size: 1em;
      transform: translateY(-30px);
    }

    i{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: white;
      border-radius: 4px;
      overflow: hidden;
      transition: 0.5s;
      pointer-events: none;
    }
    
    input:valid ~ i,
    input:focus ~ i{
      height: 35px;
      background: transparent;
      border-bottom: 3px solid white;
      border-right: 3px solid white;
      border-left: 3px solid white;
    }

    img {
      width: 18px;
      height: 18px;
      margin-top: 12px;
      margin-left: 10px;
      margin-right: 10px;
    }
    `

export default function FormField(props){
  const {width, name, inputName, value, handler, type, requiredF, icon} = props;

  return(
    <Field width={width}>
      {(value && icon) && <label htmlFor="input">
              <img src={icon} />
          </label>}
      <input 
        type={type? type: "text"}
        onChange={handler}
        value={value}
        name={inputName}
        {...(!requiredF ? { required: true } : {})}
        id="input"
        autoComplete="off"
      />
      <span>{name}</span>
      <i></i>
    </Field>
  )
}

