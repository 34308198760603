import React, { Fragment } from "react";
import styled from "styled-components"
import tw from "twin.macro";
import FormField from "./FormField.jsx";
import { FormRow } from "../styledElements";
import MainButton from "./MainButton";
import { loginInput, longInput, mainPurple, mainFucsia, mainBackground, secondaryText, shoeSizes } from "../const.js";
import {  shoeSize } from "../const.js";
import NewShoeForm from "./NewShoeForm.jsx";
import CDatePicker from "./CDatePicker.jsx";
import plus from "../icons/plus.png"
import TagList from "./TagList.jsx";
import check from "../icons/check.png"
import 'dayjs/locale/zh-cn.js'
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import CorrectPopUp from "./CorrectPopUp.jsx";
import search_icon from "../icons/search_icon.png"
import x_icon from "../icons/x_icon.png"
import TypesFiltering from "./TypesFiltering.jsx";
import roundAlert from "../icons/roundAlert.png";
import { actionTypes, useGlobalState } from "../GlobalStateContext";
import ErrorPopUp from "./ErrorPopUp.jsx";
import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";

const TodoButton = styled.button`
  @keyframes pop{
    0%{
      width: 35px;
      height: 35px;
      background: transparent;
    }
    50%{
      width: 40px;
      height: 40px;
      background: ${mainPurple};
    }
    100%{
      width: 35px;
      height: 35px;
      background: transparent;
    }
  }

  @keyframes grow{
    25%{
      transform: scale(1.2);
      background: ${mainPurple};
    }
  }

  ${tw`
      flex
      justify-center
      items-center
  `}
  width: 35px;
  height: 35px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${mainPurple};
  background: transparent;
  margin-top: -20px;
  ${props => props.animate && 'animation: grow 0.5s ease-out;'}
  
  img {
    max-width: 20px;
    max-height: 20px;
  }
`

const SelectionText = styled.div`
  color: ${secondaryText};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
  margin-left: 10px;
`

const FormContainer = styled.form`
  ${tw`
      mt-1
      px-7
      py-2
      flex
      flex-col
  `}
  padding-bottom: 200px;
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .specialRow{
    align-items: ceneter;
  }
animation: appear 0.2s ease-in-out;
`

const NoItems = styled.div`
  @keyframes appear{
    0%{
      transform: TranslateY(20px);
      opacity: 0;
    }
    100%{
      transform: TranslateY(0px);
      opacity: 1;
    }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }

  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      justify-center
      items-center
  `}

  margin-top: 30px;

  animation: appear 0.5s ease-in-out;

  span {
    font-size: 14px;
  }

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    animation: shake 0.5s ease-in-out 0.5s;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 90%;
  background: black;
  padding: 18px 10px;
  border-radius: 15px;
  text-align: center
`

const FlexContainer = styled.div`
  ${tw`
      flex
      w-full
      justify-center
  `}
`

const SneakerImage= styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;

  img{
    width: 60%;
    height: auto;
    border-radius: 20px;
  }
`

const FakeRadio = styled.div`
  ${tw`
      mr-2 
  `}
  width: 15px;
  height: 15px;
  background: transparent;
  border-width: 1px;
  border-color: ${mainPurple};
`

const RadioContainer = styled.div`
  ${tw`
      flex
      flex-row
      items-center
      justify-center
      text-white
      mb-5
      w-full
  `}
  font-size: 16px;
`

const RadioActive = styled.div`
  ${tw`
      flex
      justify-center
      items-center
      mr-2
  `}
  width: 15px;
  height: 15px;
  background: linear-gradient(to right, ${mainPurple}, ${mainFucsia});

  img {
    width: 10px;
    height: 10px;
  }
`

const GoalsTitle = styled.div`
font-size: 15px;
margin-bottom: 15px;
margin-top: -20px;
margin-left: 5px;
font-weight: 500;
color: white;
`

const GoalList = styled.div`
  display: flex;
  color: white;
  flex-wrap: wrap;
`

const SelectGoals = styled.div`
  margin-top: 10px;
`

const Goal = styled.div`
  ${tw`
      py-1
      px-3
  `}
  font-size: 14px;
  border-radius: 10px;
  background: transparent;
  ${props => props.active ? 'border: 1px solid #00dadacc;' : `border: 1px solid black;`}
  margin: 5px 5px;
  ${props => props.active && 'color: #00dada;'}
`


const FlexRow = styled.div`
  ${tw`
      flex
      flex-row
      w-full
      justify-between
      items-center
      mt-5
  `}
`

const ChoicePopUp = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      fixed
  `}
  border: 1px solid #888888;
  top: 40%;
  border-radius: 15px;
  width: 300px;

  background-color: #242424;

  .textDiv{
    color: ${secondaryText};
    text-align: center;
    font-size: 16px;
    padding: 15px 15px;
  }

  .buttonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .choiceButton{
    width: 50%;
    color: #00dada; 
    font-size: 18px;
    font-weight: 600;
    padding: 5px 10px;
    border: 1px solid #888888;
    background-color: ${mainBackground};
  }

  .left{
    border-bottom-left-radius: 15px;

  }
  .right{
    border-bottom-right-radius: 15px;
    
  }
`

const ChoiceContainer = styled.div`
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${tw`
      flex
      flex-col
      w-full
      items-center
  `}

  animation: appear 0.5s ease-in-out;
`

const SneakersContainer = styled.div`
  color: white;
  margin-top: 10px;
  padding-inline: 20px;
  width: 95%;
`

const Card = styled.div`
  ${tw`
      flex
      flex-col
  `}
  width: 100%;
  border-radius: 15px;
  border: 1px outset ${secondaryText};
  margin-bottom: 20px;
`
const Transaction = styled.div`
  ${tw`
      flex
  `}
  align-items: center;
  height: 61px;
  width: 100%;
`
const TransactionDesc = styled.div`
  ${tw`
      flex
      flex-col
      ml-3
  `}

  width: 80%;
  .item_name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-height: 1.1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-size: 16px;
    width: 95%;
  }
`
const DatabaseShoe = styled.img`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  ${props => !props.roundImg && 'border-bottom-left-radius: 15px;'}
`

const SearchContainer = styled.div`
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${tw`
      flex
      flex-col
      justify-center
      items-center
  `}
  margin-top: 40px;
  input::placeholder{
    color: rgba(255,255,255,0.8);
  }
  animation: appear 0.2s ease-in-out;
`

const TypesContainer = styled.div`
  ${tw`
      flex
      flex-row
      w-full
      justify-around
      mb-5
  `}
`

const GoBack = styled.div`
  ${tw`
      flex
      py-1
      px-3
      justify-between
      items-center
  `}
  font-size: 14px;
  color: white;
  border-radius: 10px;
  border: 1px solid #00dada;
  margin-bottom: 15px;

  img{
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
`

const SearchInputContainer = styled.div`
  position: relative;
  width: 70%;

  label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  label img {
    height 22px;
    width: 22px
  }
`


const SearchInput = styled.input`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 10px;
  padding-left: 45px;
  color: rgba(255,255,255,0.8);
  border-left: 1px solid rgba(255,255,255,0.8);
  border-bottom: 1px solid rgba(255,255,255,0.8);
  border-right: 1px solid rgba(255,255,255,0.8);
  background: transparent;

  &:focus {
    outline: none; /* Remove the default focus outline */
    background: transparent; /* Set the background to transparent */
    border-left: 2px solid aqua;
    border-bottom: 2px solid aqua;
    border-right: 2px solid aqua;
  }
`

const ChoiceSentence = styled.div`
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;

  .sentence{
    width: 240px;
    border-bottom: 1px solid white;
    line-height: 0.2em;
    margin: 10px 20px;
  }

  .lines{
    background: ${mainBackground};
    padding: 0 10px;
  }
`

const ChoiceButton = styled.div`
  border: 1px solid #00dada;
  background: transparent;
  color: #00dada;
  font-size: 18px;
  width: 220px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`

const AnimationContainer = styled.div`
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  animation: appear 0.2s ease-in-out;
`

const SNEAKER_SEARCH = gql`
  query getSneakersByName($name: String!){
    getSneakersByName(name: $name){
      SKU
      ShoeName
      ShoeImage
    }
  }
`
const StyledButton = styled.button`
  ${tw`
      px-6
      py-2
      rounded-xl 
      text-center
      font-semibold
    `}
    position: relative;
    overflow: hidden;
    font-size: 16px;
    background: transparent;
    color: #00dada;
    border: 1px solid #00dada;
`;

export default function NewItemForm(props){
  const { state, dispatch} = useGlobalState();

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };

  const { goals, oldItem, sellCallback } = props;
  const NEWITEM = gql`
    mutation createItem($args: NewItemArgs!) {
      createItem(args: $args) {
        id
        user{
          id
          firstName
        }
      }
    }
  `;

  const NEWSHOE = gql`
    mutation createShoe($args: NewShoeArgs!) {
      createShoe(args: $args) {
        id
      }
    }
  `;

  const NEWWATCH = gql`
    mutation createWatch($args: NewWatchArgs!) {
      createWatch(args: $args) {
        id
        modelNumber
      }
    }
  `;

  const [createItem, {loadingItem, errorItem, dataItem}] = useMutation(NEWITEM, {
    onError: () => {
      setNotAdded(true)
      setTimeout( () => {
        setNotAdded(false);
      }, 1000)
    },
    onCompleted: () => {
      handleSetAllDataUpdated();
      if(sellCallback){
        setTimeout( () => {
          sellCallback();
        }, 1000)
      }
      else setShowSave(true);
    }
  });
  const [createShoe, {loadingShoe, errorShoe, dataShoe}] = useMutation(NEWSHOE, {
    onError: () => {
      setNotAdded(true)
      setTimeout( () => {
        setNotAdded(false);
      }, 1000)
    },
    onCompleted: () => {
      handleSetAllDataUpdated();
      setShowSave(true);
    }
  });  
  const [createWatch, {loadingWatch, errorWatch, dataWatch}] = useMutation(NEWWATCH, {
    onError: () => {
      setNotAdded(true)
      setTimeout( () => {
        setNotAdded(false);
      }, 1000)
    },
    onCompleted: () => {
      handleSetAllDataUpdated();
      setShowSave(true);
    }
  });
  const [getSneakerByName, {loadingSKU, data: dataSKU, errorSKU}] = useLazyQuery(SNEAKER_SEARCH, {
    onError: (error) => {
      setNoSneaker(true);
    }
  });

  const [sneakers, setSneakers] = React.useState()
  const [newItem, setItem] = React.useState({
    name: oldItem ? oldItem.name : "",
    buyPrice: oldItem ? oldItem.buyPrice : null,
    sellPrice: oldItem ? oldItem.sellPrice : null,
    expectedSellPrice: oldItem ? oldItem.expectedSellPrice : null,
    saleCosts: oldItem ? oldItem.saleCosts : null,
    buyDate: oldItem ? oldItem.buyDate : "",
    sellDate: oldItem ? oldItem.sellDate : null,
    type: oldItem ? oldItem.type : "",
    sizeEu: oldItem && oldItem.shoe ? oldItem.shoe.sizeEU : "",
    modelNumber: oldItem && oldItem.watch ? oldItem.watch.modelNumber : "",
    serialNumber: oldItem && oldItem.watch ? oldItem.watch.serialNumber : "",
    gender: oldItem && oldItem.shoe ? oldItem.shoe.gender : "",
    tags: oldItem && oldItem.tags ?  oldItem.tags.map(tag => tag.text) : [],
    alreadySold: oldItem ? true : false
  })

  const [noSneaker, setNoSneaker] = React.useState(false);
  const [itemAdded, setAdded] = React.useState(false);
  const [itemNotAdded, setNotAdded] = React.useState(false);
  const [active, setActive] = React.useState(oldItem ? true : false);
  const [type, setType] = React.useState({
    generico: oldItem ? oldItem.type == "other" : false,
    scarpa: oldItem ? oldItem.type == "shoe" : false,
    orologio: oldItem ? oldItem.type == "watch" : false
  });
  const [activeKey, setKey] = React.useState(oldItem && oldItem.shoe ? oldItem.shoe.gender : Object.keys(shoeSizes)[0]);

  React.useEffect(() => {
    if(!shoeSizes[activeKey].includes(shoeSizeEU)) setShoeSize(shoeSizes[activeKey][0]);
    setItem(prevItem => ({
      ...prevItem,
      gender: activeKey
    }))
  }, [activeKey])
  const [shoeSizeEU, setShoeSize] = React.useState(oldItem && oldItem.shoe ? oldItem.shoe.sizeEU : shoeSizes[activeKey][0]);
  const [currentTag, setTag] = React.useState("");
  const [animate, setAnimate] = React.useState(false);
  const [date, setDate] = React.useState(oldItem ? dayjs(oldItem.buyDate, "YYYY-MM-DD") : null);
  const [dateSell, setSell] = React.useState(oldItem ? dayjs(oldItem.sellDate, "YYYY-MM-DD") : null);
  const [phase, setPhase] = React.useState(oldItem ? "manual" : "select");
  const [searchName, setSearch] = React.useState("");
  const [goalsUpdate, setGoals] = React.useState(oldItem && oldItem.influencingGoals ? oldItem.influencingGoals.map(goal => goal.name) : []);
  const [sneaker, setSneaker] = React.useState(null);

  function handleTag(event){
    setTag(event.target.value);
  }

  React.useEffect( () => {
    if (date){
      const dateString = date.$d.toLocaleDateString();
      const [day, month, year] = dateString.split("/");
      const formattedDate = `${year}-${month}-${day}`;
      setItem( prevItem => {
        return({
          ...prevItem,
          buyDate: formattedDate
        })
      })
    }
  }, [date])

  React.useEffect( () => {
    if (dateSell){
      const dateString = dateSell.$d.toLocaleDateString();
      const [day, month, year] = dateString.split("/");
      const formattedDate = `${year}-${month}-${day}`;
      setItem( prevItem => {
        return({
          ...prevItem,
          sellDate: formattedDate
        })
      })
    }
  }, [dateSell])

  React.useEffect( () => {
    var itemBasics = true;
    var itemSpec = true;
    var soldItem = true;
    if (!newItem.name || !newItem.buyPrice || !newItem.buyDate || !newItem.expectedSellPrice){
        itemBasics = false;
    }
    if (newItem.type == "Scarpa" && (newItem.sizeEu === "Taglia" || !newItem.sizeEu || !newItem.gender)){
      itemSpec = false;
    }
    else if (newItem.type == "Orologio" && (!newItem.serialNumber || !newItem.modelNumber)){
      itemSpec = false;
    }
    if (newItem.alreadySold && (!newItem.sellPrice || !newItem.sellDate || !newItem.saleCosts)){
      soldItem = false;
    }
    setActive(itemBasics && itemSpec && soldItem);
  }, [newItem])

  React.useEffect( () => {
    if (shoeSizeEU)
      setItem(prevItem => {
        return({
          ...prevItem,
          sizeEu: shoeSizeEU
        })
      })
  }, [shoeSizeEU])

  function selectSKU(index){
    setSneaker(sneakers[index]);
    setItem(prevItem => ({
      ...prevItem,
      name: sneakers[index].ShoeName
    }))
    setType({
      generico: false,
      scarpa: true,
      orologio: false

    })
  }

  React.useEffect( () => {
    if(dataSKU){
      setSneakers([...dataSKU.getSneakersByName])
    }
  }, [dataSKU])

  function addTag(){
    if(currentTag){
      setItem( prevItem => {
        return({
          ...prevItem,
          tags: [...prevItem.tags, currentTag]
        })
      })
      setTag("");
      setAnimate(true);
      setTimeout( () => {
        setAnimate(false);
      }, 1000);
    }
  }

  function deleteTag(index){
    const newTags = [...newItem.tags];
    newTags.splice(index, 1);
    setItem( prevItem => {
      return({
        ...prevItem,
        tags: [...newTags]
      })
    })
  }

  function handleInput(event){
    const {name, value} = event.target;
    setItem( prevItem => {
      return(
        {
          ...prevItem,
          [name]: value
        }
      )
    });
  }

  function changeSold(){
    if (newItem.alreadySold){
      setItem( prevItem => {
        return({
          ...prevItem,
          sellPrice: null,
          sellDate: null,
          saleCosts: null
        })
      })
      setSell(null);
    }
    setItem( prevItem => {
      return({
        ...prevItem,
        alreadySold: !prevItem.alreadySold
      })
    })
  }

  async function handleSubmit(event){
    event.preventDefault();
    var item = {};
    if (type.generico){
      item = {
        newName: newItem.name,
        newBuyPrice: parseInt(newItem.buyPrice),
        newExpectedSellPrice: parseInt(newItem.expectedSellPrice),
        newBuyDate: newItem.buyDate,
        newType: "other",
        newTags: [...newItem.tags],
        newSellPrice: parseInt(newItem.sellPrice),
        newSaleCosts: parseInt(newItem.saleCosts),
        newSellDate: newItem.sellDate,
        goalsUpdate: [...goalsUpdate]
      }
      try {
        createItem({
          variables: {
            args: item,
          }
        });
        setAdded(true);
        setTimeout( () => {
          setAdded(false)
        }, 1500);
      }
      catch(error){
        console.log(error);
      }
    }
    else if (type.scarpa){
      item = {
        newName: newItem.name,
        newBuyPrice: parseInt(newItem.buyPrice),
        newExpectedSellPrice: parseInt(newItem.expectedSellPrice),
        newBuyDate: newItem.buyDate,
        newType: "shoe",
        newTags: [...newItem.tags],
        newSellPrice: parseInt(newItem.sellPrice),
        newSaleCosts: parseInt(newItem.saleCosts),
        newSellDate: newItem.sellDate,
        newSizeEU: parseFloat(newItem.sizeEu),
        newGender: newItem.gender,
        goalsUpdate: [...goalsUpdate],
        sneakerSKU: (sneaker && sneaker.SKU) || null 
      }
      try {
        createShoe({
          variables: {
            args: item,
          }
        });
        setAdded(true);
        setTimeout( () => {
          setAdded(false)
        }, 1500);
      }
      catch(error){
        console.log(error);
      }
    }
    else if (type.orologio){
      item = {
        newName: newItem.name,
        newBuyPrice: parseInt(newItem.buyPrice),
        newExpectedSellPrice: parseInt(newItem.expectedSellPrice),
        newBuyDate: newItem.buyDate,
        newType: "watch",
        newTags: [...newItem.tags],
        newSellPrice: parseInt(newItem.sellPrice),
        newSaleCosts: parseInt(newItem.saleCosts),
        newSellDate: newItem.sellDate,
        newSerialNumber: newItem.serialNumber,
        newModelNumber: newItem.modelNumber,
        goalsUpdate: [...goalsUpdate]
      }
      try {
        createWatch({
          variables: {
            args: item,
          }
        });
        setAdded(true);
        setTimeout( () => {
          setAdded(false)
        }, 1500);
      }
      catch(error){
        console.log(error);
      }
    }
  }

  function setDatabaseSearch(){
    setPhase("databaseSearch");
  }

  function setManual(){
    setPhase("manual");
  }

  function handleSearch(event){
    const {value} = event.target;

    setSearch(value);
    if(value && value.length > 3){
      getSneakerByName({
        variables: {
          name: value
        }
      })
    }
  }

  function goToSelect(){
    setPhase("select");
    setSneaker(null);
    setNoSneaker(false);
  }

  function changeType(event){
    const {id} = event.target;
    if(id === "generico" && !type[id]){
      setType(prevType => {
        return({
          [id]: !prevType[id],
          scarpa: false,
          orologio: false
        })
      })
    }
    else if(id === "scarpa" && !type[id]){
      setType(prevType => {
        return({
          [id]: !prevType[id],
          orologio: false,
          generico: false
        })
      })
    }
    else if(id === "orologio" && !type[id]){
      setType(prevType => {
        return({
          [id]: !prevType[id],
          generico: false,
          scarpa: false
        })
      })
    }
    else{
      setType(prevType => {
        return({
          [id]: !prevType[id]
        })
      })
    }
  }

  function changeGoal(event){
    const {id} = event.target;
    if (goalsUpdate.length > 0 && goalsUpdate.includes(id)){
      setGoals(prevGoal => prevGoal.filter(item => item !== id))
    }
    else{
      setGoals(prevGoal => [...prevGoal, id]);
    }
  }

  function submitSearch(){
    if(searchName.length > 3){
      try{
        getSneakerByName({
          variables: {
            name: searchName
          }
        })
        setSearch(null);
        setNoSneaker(false);
      } catch(error){
        console.log(error);
      }
    }
  }

  function goManualNoSku(){
    setNoSneaker(false);
    setPhase("manual");
  }

  const [showSaveInfo, setShowSave] = React.useState(false);

  function closeShow(){
    setShowSave(false);
  }

  function clearForm(){
    setShowSave(false);
    setPhase("select");
    setItem({
      name: "",
      buyPrice: null,
      sellPrice: null,
      expectedSellPrice: null,
      saleCosts: null,
      buyDate: "",
      sellDate: null,
      type: "",
      sizeEu: "",
      modelNumber: "",
      serialNumber: "",
      gender: "",
      tags: [],
      alreadySold: false
    });
    setType({
      generico: false,
      scarpa: false,
      orologio: false
    });
    setShoeSize(shoeSize[0]);
    setTag("");
    setDate(null);
    setSell(null);
    setSearch("");
    setSneaker(null);
    setGoals([]);
  }

  function setTodayBuy(e){
    e.preventDefault();
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    console.log("hello");
    console.log(`${year}-${month}-${day}`);
    setDate(dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"))
  }

  function setTodaySell(e){
    e.preventDefault();
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    setSell(dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"))
  }

  return(
    <div>
      {phase === "select" &&
        <ChoiceContainer>
          <ChoiceButton onClick={setDatabaseSearch}>Cerca sneaker</ChoiceButton>
          <ChoiceSentence>
            <span className="sentence"><span className="lines">oppure</span></span>
          </ChoiceSentence>
          <ChoiceButton onClick={setManual}>Procedi manualmente</ChoiceButton>
        </ChoiceContainer>
      }
      {phase === "databaseSearch" &&
        <SearchContainer>
          <GoBack onClick={goToSelect}>
            <span className="text">Cerca</span>
            <img src={x_icon} />
          </GoBack>
          <SearchInputContainer>
            <label htmlFor="searchInput">
                <img src={search_icon} onClick={submitSearch}/>
            </label>
            <SearchInput
              type="text"
              id="searchInput"
              placeholder="SKU o nome della scarpa"
              value={searchName}
              onChange={handleSearch}
            />
          </SearchInputContainer>
          {sneakers && !sneaker && <SneakersContainer>
              {sneakers.map( (obj, index) => (
                <Card key={index}  onClick={() => selectSKU(index)}>
                  <Transaction id={index} key={index}>
                    <DatabaseShoe src={obj.ShoeImage} />
                    <TransactionDesc>
                      <span className="item_name">{obj.ShoeName}</span>
                    </TransactionDesc>
                  </Transaction>
                </Card>
              ))}
            </SneakersContainer>}
        </SearchContainer>
      }
      {noSneaker && phase == "databaseSearch" &&
        <NoItems>
          <img src={roundAlert} />
          <MessageContainer>
            <span>Non abbiamo nessuna scarpa nel database con questo SKU. Riprova o procedi manualmente.</span>
          </MessageContainer>
          <MainButton active={true} text="Procedi manualmente" onClickFunction={() => {goManualNoSku()}}/>
        </NoItems>
      }
      {
        sneaker && !noSneaker && phase == "databaseSearch" &&
        <FormContainer onSubmit={handleSubmit}>
          <SneakerImage>
            <img src={sneaker.ShoeImage} />
          </SneakerImage>
          <FormField  
            width={longInput}
            name="Nome"
            pHolder="Nome dell'oggetto"
            inputName="name"
            value={newItem.name}
            handler={handleInput}
          />
          <FormRow>
            <FormField width={longInput} name="Prezzo acquisto" pHolder="€" inputName="buyPrice" value={newItem.buyPrice} handler={handleInput} fontDim="15px" type="number"/>
          </FormRow>
          <FormRow>
            <FormField width={longInput} name="Vendita desiderata" pHolder="€" inputName="expectedSellPrice" value={newItem.expectedSellPrice} handler={handleInput} fontDim="15px" type="number"/>
          </FormRow>
          <FormRow className="specialRow">
            <CDatePicker title="Data acquisto" date={date} setDate={setDate}/>
            <StyledButton onClick={setTodayBuy}>Oggi</StyledButton>
          </FormRow>
          <AnimationContainer>
            <NewShoeForm activeKey={activeKey} setKey={setKey} select={setShoeSize} currSize={shoeSizeEU} />
          </AnimationContainer>
          <FlexRow>
            <FormField width={loginInput} name="Aggiungi tag" pHolder="Tag" inputName="tag" value={currentTag} handler={handleTag} requiredF={true}/>
            <TodoButton onClick={addTag} animate={animate} type="button">
              <img src={plus} />
            </TodoButton>
          </FlexRow>
          {newItem.tags.length > 0 && <TagList tags={newItem.tags} func={deleteTag}/>}
          <RadioContainer>
            {newItem.alreadySold?  <RadioActive onClick={changeSold} name="alreadySold" id="alreadySold">
              <img src={check} id="alreadySold"/>
            </RadioActive> : <FakeRadio onClick={changeSold} name="alreadySold" id="alreadySold"/>}
            <p>Già venduto?</p>
          </RadioContainer>
          {newItem.alreadySold && 
            <AnimationContainer>
              <FormRow>
                <FormField width={longInput} name="Prezzo vendita" pHolder="€" inputName="sellPrice" value={newItem.sellPrice} handler={handleInput} fontDim="15px" type="number"/>
              </FormRow>
              <FormRow>
                <FormField width={longInput} name="Cambia spese e commissioni" pHolder="€" inputName="saleCosts" value={newItem.saleCosts} handler={handleInput} fontDim="15px" type="number"/>
              </FormRow>
              <FormRow>
                <CDatePicker title="Data vendita" date={dateSell} setDate={setSell}/>
                <StyledButton onClick={setTodaySell}>Oggi</StyledButton>
              </FormRow>
            </AnimationContainer>
          }
          {(goals && newItem.alreadySold) && 
            <SelectGoals>
              <GoalsTitle>Seleziona obiettivi da aggiornare</GoalsTitle>
              <GoalList>
                {goals.map( (goal, index) => (
                  <Goal id={goal.name} key={index} onClick={changeGoal} active={goalsUpdate.includes(goal.name)}>{goal.name}</Goal>
                ))}
              </GoalList>
            </SelectGoals>

          }
          <MainButton active={active} text="Aggiungi" onClick={handleSubmit} type="submit"/>
          {itemAdded && 
            <CorrectPopUp text="L'oggetto è stato aggiunto con successo." />
          }
          {itemNotAdded && 
            <ErrorPopUp text="L'oggetto non è stato aggiunto." />
          }
        </FormContainer>
        
      }
      {phase === "manual" &&
        <FormContainer onSubmit={handleSubmit}>
          <FlexContainer>
            <GoBack onClick={goToSelect}>
              <span className="text">Manuale</span>
              <img src={x_icon} />
            </GoBack>
          </FlexContainer>
          <SelectionText>
            Seleziona il tipo
          </SelectionText>
          <TypesContainer>
            <TypesFiltering type="Generico" state={type.generico} onClickFunction={changeType} id="generico"/>
            <TypesFiltering type="Scarpa" state={type.scarpa} onClickFunction={changeType} id="scarpa"/>
            <TypesFiltering type="Orologio" state={type.orologio} onClickFunction={changeType} id="orologio"/>
          </TypesContainer>
          <FormField  
            width={longInput}
            name="Nome"
            pHolder="Nome dell'oggetto"
            inputName="name"
            value={newItem.name}
            handler={handleInput}
          />
          <FormRow>
            <FormField width={longInput} name="Prezzo acquisto" pHolder="€" inputName="buyPrice" value={newItem.buyPrice} handler={handleInput} fontDim="15px" type="number"/>
          </FormRow>
          <FormRow>
            <FormField width={longInput} name="Vendita desiderata" pHolder="€" inputName="expectedSellPrice" value={newItem.expectedSellPrice} handler={handleInput} fontDim="15px" type="number"/>
          </FormRow>
          <FormRow className="specialRow">
            <CDatePicker title="Data acquisto" date={date} setDate={setDate}/>
            <StyledButton onClick={setTodayBuy}>Oggi</StyledButton>
          </FormRow>
          {type.scarpa && 
            <AnimationContainer>
              <NewShoeForm activeKey={activeKey} setKey={setKey} select={setShoeSize} currSize={shoeSizeEU} />
            </AnimationContainer>
          }
          {type.orologio && 
            <AnimationContainer>
              <FormRow>
                <FormField width={longInput} name="Numero modello" pHolder="" inputName="modelNumber" value={newItem.modelNumber} handler={handleInput} fontDim="15px"/>
              </FormRow>
              <FormRow>
              <FormField width={longInput} name="Numero seriale" pHolder="" inputName="serialNumber" value={newItem.serialNumber} handler={handleInput} fontDim="15px"/>
            </FormRow>
            </AnimationContainer>
          }
          <FlexRow>
            <FormField width={loginInput} name="Aggiungi tag" pHolder="Tag" inputName="tag" value={currentTag} handler={handleTag} requiredF={true}/>
            <TodoButton onClick={addTag} animate={animate} type="button">
              <img src={plus} />
            </TodoButton>
          </FlexRow>
          {newItem.tags.length > 0 && <TagList tags={newItem.tags} func={deleteTag}/>}
          <RadioContainer>
            {newItem.alreadySold?  <RadioActive onClick={changeSold} name="alreadySold" id="alreadySold">
              <img src={check} id="alreadySold"/>
            </RadioActive> : <FakeRadio onClick={changeSold} name="alreadySold" id="alreadySold"/>}
            <p>Già venduto?</p>
          </RadioContainer>
          {newItem.alreadySold && 
            <AnimationContainer>
              <FormRow>
                <FormField width={longInput} name="Prezzo vendita" pHolder="€" inputName="sellPrice" value={newItem.sellPrice} handler={handleInput} fontDim="15px" type="number"/>
              </FormRow>
              <FormRow>
                <FormField width={longInput} name="Cambia spese e commissioni" pHolder="€" inputName="saleCosts" value={newItem.saleCosts} handler={handleInput} fontDim="15px" type="number"/>
              </FormRow>
              <FormRow>
                <CDatePicker title="Data vendita" date={dateSell} setDate={setSell}/>
                <StyledButton onClick={setTodaySell}>Oggi</StyledButton>
              </FormRow>
            </AnimationContainer>
          }
          {(goals && newItem.alreadySold) && 
            <SelectGoals>
              <GoalsTitle>Seleziona obiettivi da aggiornare</GoalsTitle>
              <GoalList>
                {goals.map( (goal, index) => (
                  <Goal id={goal.name} key={index} onClick={changeGoal} active={goalsUpdate.includes(goal.name)}>{goal.name}</Goal>
                ))}
              </GoalList>
            </SelectGoals>

          }
          <MainButton active={active} text="Aggiungi" onClick={handleSubmit} type="submit"/>
          {itemAdded && 
            <CorrectPopUp text="L'oggetto è stato aggiunto con successo" />
          }
          {itemNotAdded && 
            <ErrorPopUp text="L'oggetto non è stato aggiunto." />
          }
        </FormContainer>
      }
      <Transition.Root show={showSaveInfo} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showSaveInfo}
          onClose={() => closeShow()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ChoicePopUp>
                <div className="textDiv">
                  Vuoi mantenere le informazioni inserite? <br />(Per registrare un oggetto simile)
                </div>
                <div className="buttonDiv">
                  <button className="choiceButton left" onClick={() => closeShow()}>Si</button>
                  <button className="choiceButton right" onClick={() => clearForm()}>No</button>
                </div>
              </ChoicePopUp>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  ) 
}

/**
 <FormContainer>
        <FormRow>
          <CDatePicker title="Data acquisto" date={date} setDate={setDate}/>
        </FormRow>
        <FormRow>
          <CSelector width={longInput} options={typeOptions} val={type} func={setType} title="Tipo"/>
        </FormRow>
        <div className="mb-5" />
        <FormField  
          width={longInput}
          name="Nome"
          pHolder="Nome dell'oggetto"
          inputName="name"
          value={newItem.name}
          handler={handleInput}
        />
        <FormRow>
          <FormField width={longInput} name="Prezzo acquisto" pHolder="$" inputName="buyPrice" value={newItem.buyPrice} handler={handleInput} fontDim="15px" type="number"/>
        </FormRow>
        <FormRow>
          <FormField width={longInput} name="Vendita desiderata" pHolder="$" inputName="expectedSellPrice" value={newItem.expectedSellPrice} handler={handleInput} fontDim="15px" type="number"/>
        </FormRow>
        {type === "Scarpa" && <NewShoeForm val={shoeForm} func={changeShoeForm} select={setShoeSize} currSize={shoeSizeEU}/>}
        {type === "Orologio" && 
          <FormRow>
            <FormField width={longInput} name="Numero modello" pHolder="" inputName="modelNumber" value={newItem.modelNumber} handler={handleInput} fontDim="15px"/>
          </FormRow>
        }
        {type === "Orologio" && 
          <FormRow>
            <FormField width={longInput} name="Numero seriale" pHolder="" inputName="serialNumber" value={newItem.serialNumber} handler={handleInput} fontDim="15px"/>
          </FormRow>
        }
        <FlexRow>
          <FormField width={loginInput} name="Aggiungi tag" pHolder="Tag" inputName="tag" value={currentTag} handler={handleTag} requiredF={true}/>
          <TodoButton onClick={addTag} animate={animate} type="button">
            <img src={plus} />
          </TodoButton>
        </FlexRow>
        {newItem.tags.length > 0 && <TagList tags={newItem.tags} func={deleteTag}/>}
        <RadioContainer>
            {newItem.alreadySold?  <RadioActive onClick={changeSold} name="alreadySold" id="alreadySold">
              <img src={check} id="alreadySold"/>
            </RadioActive> : <FakeRadio onClick={changeSold} name="alreadySold" id="alreadySold"/>}
            <p>Già venduto?</p>
          </RadioContainer>
        {newItem.alreadySold &&
          <FormRow>
            <FormField width={longInput} name="Prezzo vendita" pHolder="$" inputName="sellPrice" value={newItem.sellPrice} handler={handleInput} fontDim="15px" type="number"/>
          </FormRow>
        }
        {newItem.alreadySold &&
          <FormRow>
            <CDatePicker title="Data vendita" date={dateSell} setDate={setSell}/>
          </FormRow>
        }
        {newItem.alreadySold &&
          <PaymentContainer>
            <p className="radio-title">Metodo di pagamento</p>
            <FormRow className="radio-row">
              <RadioContainer>
                {paymentForm.untracked?  <RadioActive onClick={changePayment} name="untracked" id="untracked">
                  <img src={check} id="untracked"/>
                </RadioActive> : <FakeRadio onClick={changePayment} name="untracked" id="untracked"/>}
                <p>Contanti</p>
              </RadioContainer>
              <RadioContainer>
                {paymentForm.tracked?  <RadioActive onClick={changePayment} name="tracked"tracked id="tracked">
                  <img src={check} id="tracked"/>
                </RadioActive> : <FakeRadio onClick={changePayment} name="tracked" id="tracked"/>}
                <p>Carta</p>
              </RadioContainer>
            </FormRow>
          </PaymentContainer>
        }
        <MainButton active={active} text="Aggiungi"/>
        {itemAdded && 
          <CorrectPopUp text="L'oggetto è stato aggiunto con successo" />
        }
      </FormContainer>
 */