import { useNavigate } from 'react-router-dom';
import cookies from '../help/cookie';
import React from 'react';


const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const accessToken = cookies.get('accessToken');

    React.useEffect(() => {
      if (!accessToken){
        navigate('/')
      }
    }, []);

    return children;
};

export default ProtectedRoute;